// app/assets/stylesheets/custom.scss

// Color Variables for Military Theme
$body-bg: #1a2942;             // Dark Navy Blue
$body-color: #ffffff;           // White text for body
$navbar-bg: #1a2942;            // Dark Blue for Navbars
$link-color: #6ae0e4;           // Light Blue for links
$btn-primary-bg: #61789e;       // Muted Blue for Primary Buttons
$btn-primary-color: #fff;       // White text on Primary Buttons
$table-header-bg: #1a2942;      // Dark Blue for Table Headers
$table-header-text: #4caf50;    // Green Text for Table Headers
$table-secondary-bg: #253d5b;   // Lighter Dark Blue for Table Rows (Theater, Callsign, Task, AO)
$table-bg: #0d1b2a;             // Dark Navy for Tables
$table-hover-bg: #162237;       // Slightly lighter navy on hover
$border-color: #ffffff;         // White borders
$input-bg: #1a2942;             // Dark Blue Input Background
$highlight-text: #ffffff;       // White for highlighted text like headers (MISSION DATA, SLOTS)

// Include Bootstrap components
@import "bootstrap";

// Body styling
body {
  background-color: $body-bg;
  color: $body-color;
}

// Navbar styling
.navbar {
  background-color: $navbar-bg;
  color: #fff;
}

// Link styling, overriding any default orange
a {
  color: $link-color;
  
  &:hover {
    color: lighten($link-color, 15%);
  }
}

// Button styling overrides
.btn-primary {
  background-color: $btn-primary-bg; // Muted Blue
  color: $btn-primary-color;         // White text
  border-color: darken($btn-primary-bg, 10%);
}

.btn-primary:hover {
  background-color: darken($btn-primary-bg, 10%);
  border-color: darken($btn-primary-bg, 15%);
}

.btn-secondary {
  background-color: #3498db;  // Military Blue
  color: #fff;
  border-color: darken(#3498db, 10%);
}

.btn-danger {
  background-color: #e74c3c;   // Red for Danger buttons
  border-color: darken(#e74c3c, 10%);
  color: #fff;
}
.btn-primary:hover,
.btn-secondary:hover,
.btn-danger:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);  // Subtle shadow on hover
  transform: translateY(-2px);               // Slight lift effect on hover
}

// Container background styling
.container {
  background-color: #1a2942;  // Dark Blue for container background
  color: #f8f9fa;             // Light text color
  border-color: #333;         // Adjust border color if necessary
}

// Ensure any `.bg-light` or `.bg-white` classes are overridden to dark theme
.bg-light,
.bg-white {
  background-color: #0d1b2a !important;  // Dark Blue
  color: #f8f9fa !important;             // Ensure text remains light
}

// Form Inputs and Selects styling
input,
select,
textarea,
.form-control {
  background-color: $input-bg;  // Dark Blue
  color: $body-color;           // White text
  border-color: $border-color;  // White border
  border-radius: 4px;
}

input:focus,
select:focus,
textarea:focus,
.form-control:focus {
  background-color: lighten($input-bg, 5%);
  border-color: $link-color;
  color: #fff;
}

// Dropdown menus
.dropdown-menu {
  background-color: $input-bg;
  color: $body-color;
}

.dropdown-item {
  background-color: transparent;
  color: $body-color;
}

.dropdown-item:hover {
  background-color: lighten($input-bg, 10%);
  color: $link-color;
}

// Table styling
.table {
  background-color: $table-bg;   // Dark Navy background
  color: $body-color;            // White text
}

.table-hover tbody tr:hover {
  background-color: $table-hover-bg;  // Lighter navy on hover
}

.table-bordered {
  border: 1px solid $border-color;  // White borders
}

// Table headers styling for top-level headers (MISSION DATA, SLOTS, LOADOUT)
.table th {
  background-color: $table-header-bg !important;   // Dark Blue for table header background
  color: $highlight-text !important;               // White text for table headers
  border-color: $border-color;                     // White borders
}

// Secondary table background for sections like Theater, Callsign, Task, AO
.table-secondary,
th.table-secondary {
  background-color: $table-secondary-bg !important;  // Lighter Dark Blue
  color: $highlight-text !important;                 // White text for section headers
}

// Alerts and notices styling
.alert-success {
  background-color: $table-header-bg;  // Dark Blue
  color: $table-header-text;           // Green text
  border-color: $border-color;         // White borders
}

// General border and background adjustments for cards, containers, and other elements
.border-dark {
  border-color: $border-color !important;
}

.bg-dark {
  background-color: $navbar-bg !important;  // Dark Blue background
}
// Modal styling for a dark theme
.modal-content {
  background-color: #1a2942;  // Dark blue background for modal content
  color: #ffffff;             // White text
  border-color: #4caf50;      // Green border for modal
}

.modal-header {
  background-color: #253d5b;  // Lighter dark blue for header contrast
  color: #ffffff;             // White text for the header
  border-bottom: 1px solid #4caf50;  // Green border line for header
}

.modal-body {
  background-color: #1a2942;  // Dark blue background for the body
  color: #ffffff;             // White text for modal content
}

.modal-footer {
  background-color: #253d5b;  // Lighter dark blue for footer contrast
  color: #ffffff;             // White text for the footer
  border-top: 1px solid #4caf50;  // Green border line for footer
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.7);  // Semi-transparent black background behind modal
}

// Adjust buttons inside modals for consistency
.modal-footer .btn {
  border-radius: 30px;  // Apply the same rounded design as other buttons
  padding: 10px 20px;
  background-color: #4caf50;  // Military Green for consistency
  color: #fff;
  border-color: #4caf50;
}

.modal-footer .btn:hover {
  background-color: darken(#4caf50, 10%);
}


// Ensure section headings are consistent (remove orange)
h1, h2, h3, h4, h5, h6 {
  color: $highlight-text !important; // White for headings
}

.waypoints_map input {
    color: black;
}
